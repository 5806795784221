import * as React from 'react';
import { EuiPanel } from '@elastic/eui';

export default (props: { response?: any}) => {

    const displayResponse = props.response
        ? JSON.stringify(props.response, null, 2)
        : '{}';

    return (
        <EuiPanel style={{overflowY: 'scroll', maxHeight: 600}}>
            <pre style={{whiteSpace: 'pre-wrap'}}>{displayResponse}</pre>
        </EuiPanel>
    );
}
