
import { useState } from 'react';

export default function useAsync<T>() {

    const defaultRunning = false;
    const defaultResult = {} as T;
    const defaultError = '';

    const [ running, setRunning ] = useState(defaultRunning);
    const [ result, setResult ] = useState(defaultResult);
    const [ error, setError ] = useState(defaultError);

    const handleResult = (resultValue: T) => {
        setResult(resultValue);
        setRunning(false);
    }

    const handleError = (error: any) => {
        setError(`${error}`);
        setRunning(false);
    }

    const executor = (promise: Promise<T>) => {

        setRunning(true);
        setResult(defaultResult);
        setError(defaultError);

        try {
            promise
                .then(handleResult)
                .catch(handleError)
        } catch (e) {
            handleError(e);
        }
    }

    const clear = () => {
        setError('');
    }

    return { running, result, error, executor, clear };
}
