import * as React from 'react';

import {
    EuiSuperSelect,
} from '@elastic/eui';

export default (props: {
    methods: string[],
    selectedOption: string,
    setSelectedOption: (option: string) => void,
}) => {

    const options = props.methods.map(method => ({ value: method, inputDisplay: method }));

    return (
        <EuiSuperSelect
            options={options}
            valueOfSelected={props.selectedOption}
            onChange={props.setSelectedOption}
            fullWidth={true}
        />
    )
}