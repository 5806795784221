import * as React from 'react';

import { ClientFactory } from '../../client';

import {
    EuiButton,
    EuiFlexGroup,
    EuiFlexItem,
    EuiPanel
} from '@elastic/eui';

import MethodSelector from './MethodSelector';
import Input from './Input';
import Output from './Output';
import Error from './Error';

import EditorState from './state';

import { clickHandler } from './actions';
import useAsync from '../hooks/useAsync';

export const Editor = () => {

    const client = new ClientFactory().makeDefaultClient();

    const methods = [
        'getUsersById',
        'postUsers',
        'getUsersSubResourceBySubResourceId'
    ];

    const stateHandler = new EditorState(methods);

    const [valid, setValid] = React.useState(false);
    const { running, error, executor, clear } = useAsync();
    const submissionDisabled = !valid || running;

    const handleClick = clickHandler(stateHandler, client, executor);
    const handleSelectedChange = (selected: string) => {
        stateHandler.setSelectedMethod(selected);
        clear();
    }

    return (
        <EuiFlexGroup direction={'column'} style={{minHeight: 400}}>
            <EuiFlexItem>
                <EuiFlexGroup direction={'row'}>
                    <EuiFlexItem>
                        <EuiFlexGroup direction={'column'}>
                            <EuiFlexItem grow={false}>
                                <MethodSelector
                                    methods={methods}
                                    selectedOption={stateHandler.getSelectedMethod()}
                                    setSelectedOption={handleSelectedChange}
                                />
                            </EuiFlexItem>
                            <EuiFlexItem grow={false}>
                                <EuiButton disabled={submissionDisabled} isLoading={running} onClick={handleClick}>Execute</EuiButton>
                            </EuiFlexItem>
                            <EuiFlexItem grow={true}>
                                <EuiPanel>
                                    <Input code={stateHandler.getCode()} storeCode={stateHandler.setCode} setValid={setValid} />
                                </EuiPanel>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem grow={true}>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <Output response={stateHandler.getResponse()} />
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
                <Error error={error} />
            </EuiFlexItem>
        </EuiFlexGroup>
    );
}
