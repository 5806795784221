
import * as React from 'react';

import MonacoEditor from 'react-monaco-editor';


export interface InputProps {
    code: string;
    storeCode: React.Dispatch<React.SetStateAction<string>>
    setValid: React.Dispatch<React.SetStateAction<boolean>>
}

export default (props: InputProps) => {
    const onChangeHandler = (contents: string, e: any) => {
        props.storeCode(contents);
    }

    const editorWillMount = (e: any) => {

        // Absurdly, there doesn't seem to be a better way of accessing validation status.
        // "Markers" is an unclear way of referring to syntax annotations (I think?).

        const setModelMarkers = e.editor.setModelMarkers;
        e.editor.setModelMarkers = (model: any, owner: string, markers: any) => {
            props.setValid(markers.length == 0);
            setModelMarkers(model, owner, markers);
        }
    }

    return (
        <MonacoEditor
                language='json'
                value={props.code}
                onChange={onChangeHandler}
                editorWillMount={editorWillMount}
                options={{
                    minimap: {enabled: false},
                    automaticLayout: true,
                }}
            />
    );
}
