import * as React from 'react';

import {
    EuiPage,
    EuiPageBody,
    EuiPageContent,
    EuiPageContentBody,
  } from '@elastic/eui';

import { Home } from './Home';
import { Editor } from './editor/Editor';
import Navigation from './Navigation';

import {
    HashRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';

export function App() {

    return (
        <Router>
            <EuiPage>
                <Navigation />
                <EuiPageBody>
                    <EuiPageContent>
                        <Switch>
                            <Route path='/editor'>
                                <Editor />
                            </Route>
                            <Route path='/'>
                                <Home />
                            </Route>
                        </Switch>
                    </EuiPageContent>
                </EuiPageBody>
            </EuiPage>
        </Router>
    );
}