import * as React from 'react';

import { EuiSideNav, EuiIcon } from '@elastic/eui';
import { useHistory, useLocation } from 'react-router-dom';

export default () => {

    const [openOnMobile, setOpenOnMobile] = React.useState(false);
    const history = useHistory();
    const location = useLocation();
    const {pathname} = location;

    const menuItem = (title: string, href: string, icon?: string) => ({
        id: title,
        name: title,
        icon: icon ? <EuiIcon type={icon} /> : null,
        isSelected: pathname === href,
        onClick: () => {
            setOpenOnMobile(false);
            history.push(href);
        },
    });

    const toggleOpenOnMobile = () => {
        setOpenOnMobile(!openOnMobile);
    }

    return (
        <EuiSideNav
            isOpenOnMobile={openOnMobile}
            toggleOpenOnMobile={toggleOpenOnMobile}
            style={{minWidth: 200}}
            items={[
                menuItem('home', '/home', 'heart'),
                menuItem('editor', '/editor', 'documentEdit'),
            ]}
        />
    );
}
