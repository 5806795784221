import { CdkSpaClient } from "@cfedk/cdkspa-client";
import EditorState from "./state";

export function clickHandler(
    stateHandler: EditorState,
    client: CdkSpaClient,
    executor: <T>(promise: Promise<T>) => void,
) {
    return async () => {

        async function makePromise(methodName: string, parsedCode: any) {

            switch(methodName) {
                case 'getUsersById':
                    return await client.GetUsersById(parsedCode);
                case 'postUsers':
                    return await client.PostUsers(parsedCode);
                case 'getUsersSubResourceBySubResourceId':
                    return await client.GetUsersSubResourceBySubResourceId(parsedCode);
                default:
                    throw `Invalid method name ${methodName}`;
            }
        }

        const promise = makePromise(stateHandler.getSelectedMethod(), JSON.parse(stateHandler.getCode()))
            .then((apiResponse: any) => apiResponse.json())
            .then((value: any) => stateHandler.setResponse(value));

        executor(promise);
    };
}
