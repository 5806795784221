
import { useSerialisedLocalStore } from '../hooks/useSerialisedLocalStore';

interface Config {
    selectedMethod: string,
    methods: {
        [method: string]: {
            code: string,
            response: string,
        }
    }
}

const valueForEachMethod = (methods: string[], value: any) => methods
    .map(method => ({[method]: value}))
    .reduce((acc, cur) => ({...acc, ...cur}), {});

export const defaultConfig = (methods: string[]): Config => ({
    selectedMethod: methods[0],
    methods: valueForEachMethod(methods, {code: '', response: ''})
});

export default class EditorState {

    private readonly config: Config;
    private readonly storeConfig: (newConfig: Config) => void;

    constructor(methods: string[]) {
        [this.config, this.storeConfig] = useSerialisedLocalStore('appConfig', defaultConfig(methods));
    }

    getSelectedMethod() {
        return this.config.selectedMethod;
    }

    getCode() {
        return this.config.methods[this.getSelectedMethod()].code;
    }

    getResponse() {
        return this.config.methods[this.getSelectedMethod()].response;
    }

    public setSelectedMethod = (newSelectedMethod: string) => {
        this.config.selectedMethod = newSelectedMethod;
        this.storeConfig(this.config);
    }

    public setCode = (newCode: string) => {
        this.config.methods[this.getSelectedMethod()].code = newCode;
        this.storeConfig(this.config);
    }

    public setResponse = (newResponse: string) => {
        this.config.methods[this.getSelectedMethod()].response = newResponse;
        this.storeConfig(this.config);
    }
}
